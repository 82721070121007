import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../AuthContextProvider';
import { getRedirectResult, GoogleAuthProvider, signInWithPopup, signInWithRedirect, signOut } from 'firebase/auth';
import { auth, firestore } from '../../firebaseConfig'; // Add firestore import
import { doc, onSnapshot } from 'firebase/firestore'; // For Firestore functions
import CheckoutButton from '../Chat/CheckoutButton';
import { Link } from 'react-router-dom';
import SocialLinksModal from './SocialLinksModal'; // Add this import
import { isMobile } from 'react-device-detect'
import FreeStuff from './FreeStuff';
import "./Dropdown.css"
import { GlobalContext } from '../../GlobalContextProvider';

const provider = new GoogleAuthProvider();

export default function Header({ absolute }) {

    const user = useContext(AuthContext);
    const [show, setShow] = useState(false);
    // const [userData, setUserData] = useState(null)
    const profileRef = useRef(null);
    const [showSocialModal, setShowSocialModal] = useState(false); // Add this state
    const [loading, setLoading] = useState(true)
    const googleRef = useRef()

    useEffect(() => {
        // Get the current URL parameters
        const params = new URLSearchParams(window.location.search);

        // Check if 'x' exists and is set to 'true'
        if (params.get('login') === 'true') {
            // googleRef.current?.click()
            
            params.delete('login');
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState({}, '', newUrl);

            signInWithRedirect(auth, provider)
                .then(() => console.log(2))
                .catch((e) => console.log(e))
        }
    }, []); // Empty dependency array to run only once on component mount

    // Function to set up real-time listener for user data
    // const setupUserListener = (uid) => {
    //     try {
    //         const userDocRef = doc(firestore, 'users', uid);

    //         // Listen for real-time updates
    //         const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
    //             if (docSnap.exists()) {
    //                 const userData = docSnap.data();
    //                 setUserData(userData)
    //                 setLoading(false)
    //             } else {
    //                 console.log("No such document!");
    //             }
    //         });

    //         return unsubscribe; // Return the unsubscribe function to stop listening later

    //     } catch (error) {
    //         console.error("Error setting up user listener: ", error);
    //     }
    // }

    const triggerRef = useRef()
    useEffect(() => {
        const handleMouseDown = (e) => {
            if (!profileRef.current?.contains(e.target) &&
                !triggerRef.current?.contains(e.target)) {
                setShow(false)
            }
        };

        document.addEventListener('mousedown', handleMouseDown);

        // let unsubscribe;
        // // Set up real-time listener if user is logged in
        // if (user?.uid) {
        //     unsubscribe = setupUserListener(user.uid);
        // }

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);

            // Unsubscribe from real-time updates when component unmounts or user changes
            // if (unsubscribe) {
            //     unsubscribe();
            // }
        }
    }, [user]);

    const signInWithGoogle = () => {


        signInWithPopup(auth, provider)
            .then((result) => {

                // console.log("RESULT: ", result)
                console.log("Log in successful")
                // const u = result.user;
                // Setup real-time listener after login
                // setupUserListener(u.uid);

            })
            .catch((error) => {
                console.error('Error during sign-in:', error);
            });



        // else {
        //     signInWithRedirect(auth, provider)
        //         .catch((error) => {
        //             console.error('Error during sign-in:', error);
        //         });
        // }
    };

    // useEffect(() => {
    //     getRedirectResult(auth)
    //         .then((result) => {
    //             console.log("R: ", result)
    //             if (result) {
    //                 const user = result.user;
    //                 // Setup real-time listener after login
    //                 setupUserListener(user.uid);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error getting redirect result:', error);
    //         });
    // }, []);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const { showRewards, setShowRewards } = useContext(GlobalContext);

    return (
        <>
            <div className="header" style={absolute ? {
                position: "absolute",
                width: "100svw"
            } : {}}>

                <a href='/' className="logo">
                    <img src="logo7.png" alt="logo"
                        height='40px' className="imgLogo" />
                    AlphaTwin
                </a>

                <div className="welcome">Welcome <span className="username">{
                    user?.displayName ?
                        user.displayName :
                        "New User"
                }</span> 👋</div>
                <div className='socials'>
                    {/* {console.log(userData)} */}


                    {
                        (user && user.subscriptionStatus) ?
                            (
                                (user?.subscriptionStatus !== 'Premium' || user?.cancelAtPeriodEnd) ?
                                    // <CheckoutButton priceId={'price_1PxbK0KwYi8l6iBvrAFic2Oc'}
                                    //     alt={user?.cancelAtPeriodEnd ? 4 : null} />
                                    <>
                                        <FreeStuff
                                            isPremiumUser={user?.subscriptionStatus ? user.subscriptionStatus === "Premium" : false}
                                            cancel={user?.cancelAtPeriodEnd}
                                        />
                                        <Link className='subscribe' to='/pricing' style={{ display: "flex", alignItems: "center" }}>Upgrade Plan</Link>
                                        <div className="dropdown-container" ref={dropdownRef}>
                                            <button
                                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                className="upgrade-button">
                                                Upgrade Plan
                                                <svg
                                                    className={`chevron-icon ${isDropdownOpen ? 'open' : ''}`}
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </button>

                                            {isDropdownOpen && (
                                                <div className="dropdown-menu">
                                                    <Link style={{ textDecoration: "none" }} to="/pricing"
                                                        className="dropdown-item purple"
                                                        onClick={() => setIsDropdownOpen(false)}
                                                    >
                                                        Upgrade Plan
                                                    </Link>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                            setIsDropdownOpen(false)
                                                            setShowRewards(true)
                                                        }}
                                                    >
                                                        🎁 Get Free Rewards!
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                    : <>
                                        <FreeStuff isPremiumUser={user?.subscriptionStatus ? user.subscriptionStatus === "Premium" : false} />
                                        <a className='tier' href="/pricing">
                                            <svg style={{
                                                color: "gold",
                                                animation: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
                                            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-crown w-4 h-4 mr-2 text-purple-400" data-id="6"><path d="M11.562 3.266a.5.5 0 0 1 .876 0L15.39 8.87a1 1 0 0 0 1.516.294L21.183 5.5a.5.5 0 0 1 .798.519l-2.834 10.246a1 1 0 0 1-.956.734H5.81a1 1 0 0 1-.957-.734L2.02 6.02a.5.5 0 0 1 .798-.519l4.276 3.664a1 1 0 0 0 1.516-.294z"></path><path d="M5 21h14"></path></svg>
                                            <span className='username'>Premium <span className='word'>Tier</span></span>
                                        </a>
                                    </>
                            ) : <></>
                    }

                    {/* Subscribe */}

                    <div style={{ position: "relative" }}>
                        <img ref={triggerRef} src={
                            (user && user.photoURL) ?
                                user.photoURL : 'user.svg'
                        } className={`user ${user?.subscriptionStatus === 'Premium' && "Premium"}`} width='40'
                            onClick={() => setShow(s => !s)} />


                        {/* DROPDOWN! */}


                        <div ref={profileRef} className={`dropdown ${show ? "show" : "noShow"}`}>


                            {user ?

                                <>
                                    {/* <div className="dropdownItem">
                                        <svg className='notSignedIn' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                                        <div className='notSignedIn'>{user.displayName}</div>
                                    </div> */}

                                    <Link to='/pricing' className="dropdownItem">
                                        {/* <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M223.75 130.75L154.62 15.54A31.997 31.997 0 0 0 127.18 0H16.03C3.08 0-4.5 14.57 2.92 25.18l111.27 158.96c29.72-27.77 67.52-46.83 109.56-53.39zM495.97 0H384.82c-11.24 0-21.66 5.9-27.44 15.54l-69.13 115.21c42.04 6.56 79.84 25.62 109.56 53.38L509.08 25.18C516.5 14.57 508.92 0 495.97 0zM256 160c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm92.52 157.26l-37.93 36.96 8.97 52.22c1.6 9.36-8.26 16.51-16.65 12.09L256 393.88l-46.9 24.65c-8.4 4.45-18.25-2.74-16.65-12.09l8.97-52.22-37.93-36.96c-6.82-6.64-3.05-18.23 6.35-19.59l52.43-7.64 23.43-47.52c2.11-4.28 6.19-6.39 10.28-6.39 4.11 0 8.22 2.14 10.33 6.39l23.43 47.52 52.43 7.64c9.4 1.36 13.17 12.95 6.35 19.59z"></path></svg> */}
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"></path></svg>

                                        {/* <span className={`${userData?.subscriptionStatus === 'Basic' ? "" : "username"}`} style={{ color: userData?.subscriptionStatus === 'Basic' ? "#58009F" : "" }}>
                                                {userData?.subscriptionStatus}
                                            </span>
                                            <span style={{ marginLeft: ".2em" }}>
                                                Plan
                                            </span> */}
                                        My Subscription

                                    </Link>
                                    <Link to='/affiliate' className="dropdownItem">
                                        <svg stroke="#58009F" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M18.1 6c-1.1 2.913 -1.9 4.913 -2.4 6c-1.879 4.088 -3.713 6 -6 6c-2.4 0 -4.8 -2.4 -4.8 -6s2.4 -6 4.8 -6c2.267 0 4.135 1.986 6 6c.512 1.102 1.312 3.102 2.4 6"></path></svg>
                                        Affiliates
                                    </Link>
                                </>

                                :

                                <>
                                    <div className="dropdownItem">
                                        <div className='notSignedIn'>Not Signed In</div>
                                    </div>
                                    <div className="dropdownItem" onClick={signInWithGoogle} ref={googleRef}>
                                        <img alt='google' src="google.svg" width={'18'} />
                                        <span>
                                            Google Login
                                        </span>
                                    </div>
                                </>
                            }


                            <hr className='h' />
                            <a href='https://alphatwin.ai/#contact' target="_blank" className="dropdownItem">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                                <div>Contact Us</div>
                            </a>
                            <a href='https://alphatwin.ai/#faq' target="_blank" className="dropdownItem">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path></svg>
                                <div>FAQ</div>
                            </a>
                            <div className="dropdownItem" onClick={() => setShowSocialModal(true)}>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path></svg>
                                Social Links
                            </div>

                            <a className="dropdownItem" href='https://discord.gg/5A2ycfXRdv' target="_blank" rel="noreferrer">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
                                Join Community
                            </a>


                            {
                                user ? <><hr className='h' />

                                    <div className="dropdownItem" style={{ paddingBottom: ".3em" }} onClick={() => {
                                        signOut(auth)
                                            .then(() => {
                                                console.log("User signed out successfully");
                                            })
                                            .catch((error) => {
                                                console.error("Error signing out: ", error);
                                            });
                                    }}>
                                        <svg stroke-width="0" fill='white' stroke='white' viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><g id="Logout"><g><path d="M20.968,18.448a2.577,2.577,0,0,1-2.73,2.5c-2.153.012-4.306,0-6.459,0a.5.5,0,0,1,0-1c2.2,0,4.4.032,6.6,0,1.107-.016,1.589-.848,1.589-1.838V5.647A1.546,1.546,0,0,0,19,4.175a3.023,3.023,0,0,0-1.061-.095H11.779a.5.5,0,0,1,0-1c2.224,0,4.465-.085,6.687,0a2.567,2.567,0,0,1,2.5,2.67Z"></path><path d="M3.176,11.663a.455.455,0,0,0-.138.311c0,.015,0,.028-.006.043s0,.027.006.041a.457.457,0,0,0,.138.312l3.669,3.669a.5.5,0,0,0,.707-.707L4.737,12.516H15.479a.5.5,0,0,0,0-1H4.737L7.552,8.7a.5.5,0,0,0-.707-.707Z"></path></g></g></svg>
                                        <span>
                                            Logout
                                        </span>

                                    </div> </> : <></>
                            }
                        </div>



                    </div>
                </div>
            </div>
            <SocialLinksModal
                isOpen={showSocialModal}
                onClose={() => setShowSocialModal(false)}
            />
        </>
    )
}
